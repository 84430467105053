// custom functions
var FE = {
	location: window.location,

    Lightbox: {
      init: function () {
          $('.lightbox').magnificPopup({
              type:'image',
              gallery:{enabled:true}
          });
      }
    },

	MobileMenu: {
		init: function() {
            //FE.MobileMenu.indicateSubMenu();
			$('.burger-icon').click(function() {
                $('.nav-main').slideToggle();
            });
		},
        indicateSubMenu: function() {
            $('.nav-mobile > ul > li').each(function() {
                if ( $(this).find('ul').length ) {
                    $(this).prepend('<span>&gt;</span>');
                }
                $(this).find('span').click(function() {
                    $(this).siblings('ul').slideToggle();
                });
            });
        }
	},

    NewsSlider: {
        init: function() {
            $('.news-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: false,
                arrows: true,
                infinite: true,
                pauseOnHover: true,
                speed: 1000,
                fade: false,
                slide: '.article-type-slider',
                cssEase: 'linear',
                autoplay: true,
                autoplaySpeed: 4000,
                centerMode: false,
                focusOnSelect: false
            });
        }
    },

    StartSlider: {
        init: function() {
            $('.start-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: false,
                arrows: true,
                infinite: true,
                pauseOnHover: true,
                speed: 1000,
                fade: false,
                slide: '.slider-element',
                cssEase: 'linear',
                autoplay: true,
                autoplaySpeed: 3000,
                centerMode: false,
                focusOnSelect: false
            });
        }
    },

    GallerySlider: {
        init: function() {
            $('.gallery-slider').slick({	
                slide: '.gallery-element',
                slidesToShow: 2,
                slidesToScroll: 2,
				adaptiveHeight: false,
                infinite: true,
                centerMode: false,
                dots: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 6000,
                pauseOnHover: true,	
                speed: 1000,
                cssEase: 'linear',
				responsive: [
					{
					  breakpoint: 460,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					  }
					}
				]
                // fade: true,
			/*
                focusOnSelect: false,
				lazyLoad: 'ondemand'
			*/				
			});
        }
    },

    NewsletterOverlay: {
        init: function () {
            // integrate display only once per week via localStorage and store an expire timestamp there
            if ( this.isOverlayStorageItemNotAvailableOrExpired() ) {
                setTimeout(this.openOverlay, 5000);
            }
        },
        openOverlay: function () {
            const now = new Date();
            const expireDate = now.getTime() + (3600 * 24 * 7 * 1000);  // one week in the future
            localStorage.setItem('newsletterOverlay', expireDate.toString());
            document.querySelector('.frame-99').classList.add('show');
            $.magnificPopup.open({
                items: {
                    src: '.frame-99'
                },
                type: 'inline',
                mainClass: 'newsletter-overlay'
            });
        },
        isOverlayStorageItemNotAvailableOrExpired: function () {
            const itemStr = localStorage.getItem('newsletterOverlay');
            // if the item doesn't exist no need for expiration check
            if (!itemStr) {
                return true;
            }
            const now = new Date();

            // check if last
            return now.getTime() > parseInt(itemStr);
        }
    },


    Video: {
        userConsentIsAvailable: false,
        init: function () {
            document.querySelectorAll('.video-keep-data-privacy[data-video-type="youtube"]').forEach(function (videoItem) {
                //console.log(videoItem);
                //videoItem.addEventListener('click', FE.Video.checkVideoLoading);
                videoItem.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    FE.Video.loadVideo(e.currentTarget);
                });
            });
        },
        loadVideo: function (videoItem) {
            FE.Video.userConsentIsAvailable = this.isExternalMediaConsentAvailableInConsentManager();
            const videoId = videoItem.dataset.videoId;
            const videoArea = videoItem.parentElement;

            FE.Video.addUserConsent(videoArea, videoId);

            //console.log(videoArea);
            //console.log(this.userConsentIsAvailable);

            // check potential permission via consent manager or any other persistent data such as session storage or cookie
            if ( FE.Video.userConsentIsAvailable ) {
                videoArea.innerHTML = FE.Video.getYouTubeEmbedCode(videoId);
            } else {
                videoItem.nextElementSibling.classList.add('show');
            }

        },
        embedVideo: function (videoArea, videoId) {
            videoArea.innerHTML = FE.Video.getYouTubeEmbedCode(videoId);
        },
        getVimeoEmbedCode: function (videoId) {
            return '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + videoId + '?h=96a48e54c0&byline=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>';
        },
        getYouTubeEmbedCode: function (videoId) {
            return '<iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/' + videoId + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        },
        addUserConsent: function (videoArea, videoId) {
            const videoConsentHtml = `An dieser Stelle laden wir ein externes Video von YouTube.<br>
                                        Mir ist bewusst, dass dabei Daten mit den YouTube Servern ausgetauscht werden.<br>
                                        Weitere Informationen zu den <a href="https://policies.google.com/technologies/cookies?hl=de" target="_blank" rel="noopener">YouTube Datenschutzbedingungen</a>.
                                        <div class="btn-group"><button class="btn-video-confirm">Geht klar</button><button class="btn-video-deny">möchte ich nicht</button></div>
                                        <div class="save-youtube-consent-confirm"><input type="checkbox" name="save-youtube-consent-confirm" id="save-youtube-consent-confirm" checked="checked"><label for="save-youtube-consent-confirm">Meine Auswahl merken</label></div>`;

            const videoConsent = document.createElement('div');
            videoConsent.classList.add('external-video-consent');
            videoConsent.innerHTML = `<div class="external-video-consent-inner">${videoConsentHtml}</div>`;
            videoArea.append(videoConsent);
            videoArea.querySelector('.btn-video-confirm').addEventListener('click', function (e) {
                if (videoArea.querySelector('#save-youtube-consent-confirm').checked) {
                    FE.Video.storeYouTubeDecisionInConsentManagerCookie();
                }
                FE.Video.embedVideo(videoArea, videoId);
                FE.Video.userConsentIsAvailable = true;
            });
            videoArea.querySelector('.btn-video-deny').addEventListener('click', function (e) {
                e.target.closest('.external-video-consent').classList.remove('show');
            });
        },
        isExternalMediaConsentAvailableInConsentManager: function () {
            let consentCookieContent = FE.Video.getConsentManagerCookieContent();
            if ( consentCookieContent === null ) {
                return false;
            }
            return consentCookieContent.external_media === true;
        },
        storeYouTubeDecisionInConsentManagerCookie: function () {
            const cookieExpiresInDays = 365;  // default value in cookie consent manager
            const cookieDomain = 'mkk-ingolstadt.de';
            const name = 'cookie_consent';
            let consentCookieContent = FE.Video.getConsentManagerCookieContent();
            if ( consentCookieContent !== null ) {  // should not be non-existent / null as consent cookie is somehow mandatory
                // as we can not access cookie metadata such as expire data, domain, etc. we need to set the cookie again from scratch
                consentCookieContent.external_media = true;
                const value = JSON.stringify(consentCookieContent);
                const expiresDate = new Date();
                expiresDate.setTime(expiresDate.getTime() + (cookieExpiresInDays * 24 * 60 * 60 * 1000)); // a year
                document.cookie = `${name}=${value};expires=${expiresDate.toUTCString()};path=/;sameSite=Lax;domain=${cookieDomain}`;

            }
        },
        getConsentManagerCookieContent: function () {
            let consentCookieContent = document.cookie.match(new RegExp('cookie_consent=([^;]+)'));
            if ( consentCookieContent === null ) {
                return null;
            }
            return JSON.parse(consentCookieContent[1]);
        }
    }

};

// init custom functions
(function ($, window, document, undefined) {

	$(function () {
        FE.Lightbox.init();
		FE.MobileMenu.init();
        if ( $('.news-slider').length ) {
            FE.NewsSlider.init();
        }
        if ( $('.slider-element').length ) {
            FE.StartSlider.init();
        }
        if ( $('.gallery-element').length ) {
            FE.GallerySlider.init();
			
			//setTimeout(function(){ 
			
				$('.gallery-slider').each( function() {
					var count = 0;
					$('.slick-dots li',this).each( function() {
						count = count + 1;
						$(this).text(count);
					});
					//console.log(count);
					$('.slick-dots',this).append('<li class="counter">/ ' + count + '</li>');
				});
			
			//}, 100);
        }

        if (document.querySelector('.frame-99')) {
            FE.NewsletterOverlay.init();
        }

        if ( document.querySelector('.ce-video') ) {
            FE.Video.init();
        }

        // add target blank for Facebook link
        $('.nav-footer li:first-child a').attr('target', '_blank');

	});

}(jQuery, window, document));